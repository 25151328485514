import React, { ReactElement } from 'react'

import '../i18n/i18n'

import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import SVGSymbols from '~components/homepage/svg/icons/svg'
import CookiesPopup from '~components/homepage/sections/cookies-popup'

import {
  Container,
  ContentContainer,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../fonts/css/averta-font.css'

import GlobalStyle from '~components/homepage/sections/global-styles'
import Header from '~components/homepage/sections/header'
import FooterSection from '~components/homepage/sections/footer-section'
import { Helmet } from '~components/helmet'

const StyledContentContainer = styled(ContentContainer)`
  padding-top: 120px;
  padding-left: 100px;
  padding-right: 100px;
`

const ArticlesPage = ({ t, i18n }): ReactElement => {
  const switchLang = (lng: string): void => i18n.changeLanguage(lng)

  return (
    <SectionWrapper bgColor="#F7F5F1">
      <Helmet />
      <Container>
        <GlobalStyle />
        <LanguageContext.Provider value={{ t, lang: i18n.language }}>
          <CookiesPopup />
          <Header onSwitchLang={switchLang} />
          <SVGSymbols />
          <StyledContentContainer>
            <br />
            <br />
            <br />
            <h1>
              Polityka prywatności opisuje zasady przetwarzania przez nas
              informacji na Tw&oacute;j temat, w tym danych osobowych oraz
              ciasteczek, czyli tzw. cookies.
            </h1>
            <br />
            <h4>
              <hr />
            </h4>
            <br />
            <h4>1. Informacje og&oacute;lne</h4>
            <ol>
              <li>
                <h4>
                  Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
                  adresem url: www.klosy.pl
                </h4>
              </li>
              <li>
                <h4>
                  Operatorem serwisu oraz Administratorem danych osobowych jest:
                  KŁOSY SP. Z O. O. zarejestrowana przy Rzecznej 22, 05-504
                  Złotokłos, z siedzibą przy Tamka 40, 00-355 Warszawa.
                </h4>
              </li>
              <li>
                <h4>
                  Adres kontaktowy poczty elektronicznej operatora:
                  kontakt@klosy.pl
                </h4>
              </li>
              <li>
                <h4>
                  Operator jest Administratorem Twoich danych osobowych w
                  odniesieniu do danych podanych dobrowolnie w Serwisie.
                </h4>
              </li>
              <li>
                <h4>
                  Serwis wykorzystuje dane osobowe w następujących celach:
                </h4>
                <ol>
                  <li>
                    <h4>Obsługa zapytań przez formularz</h4>
                  </li>
                  <li>
                    <h4>Przygotowanie, pakowanie, wysyłka towar&oacute;w</h4>
                  </li>
                  <li>
                    <h4>Realizacja zam&oacute;wionych usług</h4>
                  </li>
                  <li>
                    <h4>Windykacja należności</h4>
                  </li>
                  <li>
                    <h4>Prezentacja oferty lub informacji</h4>
                  </li>
                </ol>
              </li>
              <br />
              <li>
                <h4>
                  Serwis realizuje funkcje pozyskiwania informacji o
                  użytkownikach i ich zachowaniu w następujący spos&oacute;b:
                </h4>
                <ol>
                  <li>
                    <h4>
                      Poprzez dobrowolnie wprowadzone w formularzach dane,
                      kt&oacute;re zostają wprowadzone do system&oacute;w
                      Operatora.
                    </h4>
                  </li>
                  <li>
                    <h4>
                      Poprzez zapisywanie w urządzeniach końcowych plik&oacute;w
                      cookie (tzw. &bdquo;ciasteczka&rdquo;).
                    </h4>
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <h4>2. Wybrane metody ochrony danych stosowane przez Operatora</h4>
            <ol>
              <li>
                <h4>
                  Miejsca logowania i wprowadzania danych osobowych są chronione
                  w warstwie transmisji (certyfikat SSL). Dzięki temu dane
                  osobowe i dane logowania, wprowadzone na stronie, zostają
                  zaszyfrowane w komputerze użytkownika i mogą być odczytane
                  jedynie na docelowym serwerze.
                </h4>
              </li>
              <li>
                <h4>
                  W serwisie jest stosowana autentykacja dwuskładnikowa, co
                  stanowi dodatkową formę ochrony logowania do Serwisu.
                </h4>
              </li>
            </ol>
            <h4>3. Hosting</h4>
            <ol>
              <li>
                <h4>
                  Serwis jest hostowany (technicznie utrzymywany) na serwerach
                  operatora: Netlify.com / NSone
                </h4>
              </li>
            </ol>
            <h4>
              4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania
              danych
            </h4>
            <ol>
              <li>
                <h4>
                  W niekt&oacute;rych sytuacjach Administrator ma prawo
                  przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie
                  to niezbędne do wykonania zawartej z Tobą umowy lub do
                  zrealizowania obowiązk&oacute;w ciążących na Administratorze.
                  Dotyczy to takich grup odbiorc&oacute;w:
                </h4>
                <ul>
                  <li>
                    <h4>firma hostingowa na zasadzie powierzenia</h4>
                  </li>
                  <li>
                    <h4>kurierzy</h4>
                  </li>
                  <li>
                    <h4>operatorzy pocztowi</h4>
                  </li>
                  <li>
                    <h4>kancelarie prawne i windykatorzy</h4>
                  </li>
                  <li>
                    <h4>operatorzy płatności</h4>
                  </li>
                  <li>
                    <h4>
                      upoważnieni pracownicy i wsp&oacute;łpracownicy,
                      kt&oacute;rzy korzystają z danych w celu realizacji celu
                      działania strony
                    </h4>
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <h4>
                  Twoje dane osobowe przetwarzane przez Administratora nie
                  dłużej, niż jest to konieczne do wykonania związanych z nimi
                  czynności określonych osobnymi przepisami (np. o prowadzeniu
                  rachunkowości). W odniesieniu do danych marketingowych dane
                  nie będą przetwarzane dłużej niż przez 3 lata.
                </h4>
              </li>
              <li>
                <h4>Przysługuje Ci prawo żądania od Administratora:</h4>
                <ul>
                  <li>
                    <h4>dostępu do danych osobowych Ciebie dotyczących,</h4>
                  </li>
                  <li>
                    <h4>ich sprostowania,</h4>
                  </li>
                  <li>
                    <h4>usunięcia,</h4>
                  </li>
                  <li>
                    <h4>
                      ograniczenia przetwarzania,oraz przenoszenia danych.
                    </h4>
                  </li>
                </ul>
              </li>
            </ol>
            <br />
            <ol start={4}>
              <li>
                <h4>
                  Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
                  przetwarzania wskazanego w pkt 3.3 c wobec przetwarzania
                  danych osobowych w celu wykonania prawnie uzasadnionych
                  interes&oacute;w realizowanych przez Administratora, w tym
                  profilowania, przy czym prawo sprzeciwu nie będzie mogło być
                  wykonane w przypadku istnienia ważnych prawnie uzasadnionych
                  podstaw do przetwarzania, nadrzędnych wobec Ciebie
                  interes&oacute;w, praw i wolności, w szczeg&oacute;lności
                  ustalenia, dochodzenia lub obrony roszczeń.
                </h4>
              </li>
              <li>
                <h4>
                  Na działania Administratora przysługuje skarga do Prezesa
                  Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193
                  Warszawa.
                </h4>
              </li>
              <li>
                <h4>
                  Podanie danych osobowych jest dobrowolne, lecz niezbędne do
                  obsługi Serwisu.
                </h4>
              </li>
              <li>
                <h4>
                  W stosunku do Ciebie mogą być podejmowane czynności polegające
                  na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w
                  celu świadczenia usług w ramach zawartej umowy oraz w celu
                  prowadzenia przez Administratora marketingu bezpośredniego.
                </h4>
              </li>
              <li>
                <h4>
                  Dane osobowe nie są przekazywane do kraj&oacute;w trzecich w
                  rozumieniu przepis&oacute;w o ochronie danych osobowych.
                  Oznacza to, że nie przesyłamy ich poza teren Unii
                  Europejskiej.
                </h4>
              </li>
            </ol>
            <h4>5. Informacje w formularzach</h4>
            <ol>
              <li>
                <h4>
                  Serwis zbiera informacje podane dobrowolnie przez użytkownika,
                  w tym dane osobowe, o ile zostaną one podane.
                </h4>
              </li>
              <li>
                <h4>
                  Serwis może zapisać informacje o parametrach połączenia
                  (oznaczenie czasu, adres IP).
                </h4>
              </li>
              <li>
                <h4>
                  Serwis, w niekt&oacute;rych wypadkach, może zapisać informację
                  ułatwiającą powiązanie danych w formularzu z adresem e-mail
                  użytkownika wypełniającego formularz. W takim wypadku adres
                  e-mail użytkownika pojawia się wewnątrz adresu url strony
                  zawierającej formularz.
                </h4>
              </li>
              <li>
                <h4>
                  Dane podane w formularzu są przetwarzane w celu wynikającym z
                  funkcji konkretnego formularza, np. w celu dokonania
                  rezerwacji na warsztaty, &nbsp;przyjęcia zam&oacute;wienia,
                  rejestracji usług itp. Każdorazowo kontekst i opis formularza
                  w czytelny spos&oacute;b informuje, do czego on służy.
                </h4>
              </li>
            </ol>
            <h4>6. Logi Administratora</h4>
            <ol>
              <li>
                <h4>
                  Informacje zachowaniu użytkownik&oacute;w w serwisie mogą
                  podlegać logowaniu. Dane te są wykorzystywane w celu
                  administrowania serwisem.
                </h4>
              </li>
            </ol>
            <h4>7. Istotne techniki marketingowe</h4>
            <ol>
              <li>
                <h4>
                  Operator stosuje analizę statystyczną ruchu na stronie,
                  poprzez Google Analytics (Google Inc. z siedzibą w USA).
                  Operator nie przekazuje do operatora tej usługi danych
                  osobowych, a jedynie zanonimizowane informacje. Usługa bazuje
                  na wykorzystaniu ciasteczek w urządzeniu końcowym użytkownika.
                  W zakresie informacji o preferencjach użytkownika gromadzonych
                  przez sieć reklamową Google użytkownik może przeglądać i
                  edytować informacje wynikające z plik&oacute;w cookies przy
                  pomocy narzędzia: https://www.google.com/ads/preferences/
                </h4>
              </li>
              <li>
                <h4>
                  Operator korzysta z narzędzia Piksel firmy Meta. Ta
                  technologia powoduje, że serwis Facebook (Meta Inc. z siedzibą
                  w USA) wie, że dana osoba w nim zarejestrowana korzysta z
                  Serwisu. Bazuje w tym wypadku na danych, wobec kt&oacute;rych
                  sam jest administratorem, Operator nie przekazuje od siebie
                  żadnych dodatkowych danych osobowych serwisowi Facebook.
                  Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu
                  końcowym użytkownika.
                </h4>
              </li>
            </ol>
            <h4>8. Informacja o plikach cookies</h4>
            <ol>
              <li>
                <h4>Serwis korzysta z plik&oacute;w cookies.</h4>
              </li>
              <li>
                <h4>
                  Pliki cookies (tzw. &bdquo;ciasteczka&rdquo;) stanowią dane
                  informatyczne, w szczeg&oacute;lności pliki tekstowe,
                  kt&oacute;re przechowywane są w urządzeniu końcowym
                  Użytkownika Serwisu i przeznaczone są do korzystania ze stron
                  internetowych Serwisu. Cookies zazwyczaj zawierają nazwę
                  strony internetowej, z kt&oacute;rej pochodzą, czas
                  przechowywania ich na urządzeniu końcowym oraz unikalny numer.
                </h4>
              </li>
              <li>
                <h4>
                  Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
                  Serwisu pliki cookies oraz uzyskującym do nich dostęp jest
                  operator Serwisu.
                </h4>
              </li>
              <li>
                <h4>Pliki cookies wykorzystywane są w następujących celach:</h4>
                <ol>
                  <li>
                    <h4>
                      utrzymanie sesji użytkownika Serwisu (po zalogowaniu),
                      dzięki kt&oacute;rej użytkownik nie musi na każdej
                      podstronie Serwisu ponownie wpisywać loginu i hasła;
                    </h4>
                  </li>
                  <li>
                    <h4>
                      realizacji cel&oacute;w określonych powyżej w części
                      &quot;Istotne techniki marketingowe&quot;;
                    </h4>
                  </li>
                </ol>
              </li>
              <br />
              <li>
                <h4>
                  W ramach Serwisu stosowane są dwa zasadnicze rodzaje
                  plik&oacute;w cookies: &bdquo;sesyjne&rdquo; (session cookies)
                  oraz &bdquo;stałe&rdquo; (persistent cookies). Cookies
                  &bdquo;sesyjne&rdquo; są plikami tymczasowymi, kt&oacute;re
                  przechowywane są w urządzeniu końcowym Użytkownika do czasu
                  wylogowania, opuszczenia strony internetowej lub wyłączenia
                  oprogramowania (przeglądarki internetowej).
                  &bdquo;Stałe&rdquo; pliki cookies przechowywane są w
                  urządzeniu końcowym Użytkownika przez czas określony w
                  parametrach plik&oacute;w cookies lub do czasu ich usunięcia
                  przez Użytkownika.
                </h4>
              </li>
              <li>
                <h4>
                  Oprogramowanie do przeglądania stron internetowych
                  (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza
                  przechowywanie plik&oacute;w cookies w urządzeniu końcowym
                  Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień
                  w tym zakresie. Przeglądarka internetowa umożliwia usunięcie
                  plik&oacute;w cookies. Możliwe jest także automatyczne
                  blokowanie plik&oacute;w cookies Szczeg&oacute;łowe informacje
                  na ten temat zawiera pomoc lub dokumentacja przeglądarki
                  internetowej.
                </h4>
              </li>
              <li>
                <h4>
                  Ograniczenia stosowania plik&oacute;w cookies mogą wpłynąć na
                  niekt&oacute;re funkcjonalności dostępne na stronach
                  internetowych Serwisu.
                </h4>
              </li>
              <li>
                <h4>
                  Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
                  Serwisu wykorzystywane mogą być r&oacute;wnież przez
                  wsp&oacute;łpracujące z operatorem Serwisu podmioty, w
                  szczeg&oacute;lności dotyczy to firm: Google (Google Inc. z
                  siedzibą w USA), Facebook i Instagram (Meta Inc. z siedzibą w
                  USA),&nbsp;
                </h4>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h4>
              9. Zarządzanie plikami cookies &ndash; jak w praktyce wyrażać i
              cofać zgodę?
            </h4>
            <ol>
              <li>
                <h4>
                  Jeśli użytkownik nie chce otrzymywać plik&oacute;w cookies,
                  może zmienić ustawienia przeglądarki. Zastrzegamy, że
                  wyłączenie obsługi plik&oacute;w cookies niezbędnych dla
                  proces&oacute;w uwierzytelniania, bezpieczeństwa, utrzymania
                  preferencji użytkownika może utrudnić, a w skrajnych
                  przypadkach może uniemożliwić korzystanie ze stron www.
                </h4>
              </li>
            </ol>
            <ol start={2}>
              <li>
                <h4>
                  W celu zarządzania ustawienia cookies, skorzystaj z instrukcji
                  udostępnionych przez Administratora twojej przeglądarki
                  internetowej np. Google (Chrome), Mozilla(Thunderbird)
                  itp.&nbsp;
                </h4>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p>
              W razie wątpliwości dot. Naszej polityki prywatności skontaktuj
              się z nami : KŁOSY Sp. z o. o. Ul. Tamka 40, 00-355 Warszawa, tel
              600 113 805,&nbsp;
              <a href="mailto:kontakt@klosy.pl">kontakt@klosy.pl</a> .
            </p>
          </StyledContentContainer>
          <FooterSection />
        </LanguageContext.Provider>
      </Container>
    </SectionWrapper>
  )
}

export default withTranslation('translation')(ArticlesPage)
